<template>
  <step-intro-layout :objectives="objectives" @go-to-lesson="goToNextStep" class="module2-part3-intro"></step-intro-layout>
</template>

<script>
import StepIntroLayout from '@/components/training/StepIntroLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'Module2Part3Intro',
  components: { StepIntroLayout },
  emits: ['next-step', 'step-finished'],
  setup (props, context) {
    const { goToNextStep } = useTrainingUtils()
    return { goToNextStep }
  },
  data () {
    return {
      objectives: [
        this.$t('module2.part3.intro.objectif1'),
        this.$t('module2.part3.intro.objectif2'),
        this.$t('module2.part3.intro.objectif3')
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.module2-part3-intro {
  background-image: url('../../assets/module2/part3/m2p3-intro-bg.svg');
  background-repeat: no-repeat;
  background-position: right -30px top 50px;
  background-size: 40% auto;
}

@media (min-width: $bp-tablet) {
  .module2-part3-intro {
    background-position: right 5% top 20px;
    background-size: 30% auto;
  }
}
</style>
